
    import {SelectTypes} from 'ant-design-vue/es/select';
    import baseUrl from "@/util/api"
    import {ValidateErrorEntity} from 'ant-design-vue/es/form/interface';
    import {Moment} from 'moment';
    import {defineComponent, reactive, ref, toRaw, UnwrapRef, onMounted,getCurrentInstance} from 'vue';
    import {
        InboxOutlined,
        ClockCircleOutlined
    } from '@ant-design/icons-vue';
    import {message} from 'ant-design-vue';
    import {submit, isSame, getUser} from '@/network/customer';
    import {useStore} from "vuex";

    interface FormState {
        creator: string | null;
        sn: string;
        sku: string;
        file: string[];
        type: string;
        description: string;
        fixer: any;
        time: string;
        priority: string;
        customTime: number;
    }

    interface FileItem {
        uid: string;
        name?: string;
        status?: string;
        response?: any;
        url?: string;
    }

    interface FileInfo {
        file: FileItem;
        fileList: FileItem[];
    }

    interface Value {
        value?: string;
        label?: string;
    }
    interface Value1 {
        value: any;
    }

    export default defineComponent({
        name: "Cadd",
        setup() {
            const internalInstance:any = getCurrentInstance();
            const formRef = ref();
            const store = useStore();
            const options = ref<SelectTypes['options']>([]);
            const formState: UnwrapRef<FormState> = reactive({
                creator: window.localStorage.getItem('uid'),
                sn: '',
                sku: '',
                file: [],
                type: '',
                description: '',
                fixer: undefined,
                time: '',
                priority: '',
                customTime: 2,
            });
            const fileList = ref<FileItem[]>([]);
            const rules = {
                sn: [
                    {required: true, message: '请输入马帮订单编号', trigger: 'blur'}
                ],
                sku: [{required: true, message: '请输入sku', trigger: 'blur'}],
                //file: [{type: 'array', required: true, message: '请上传产品截图', trigger: 'blur'}],
                type: [
                    {
                        required: true,
                        message: '请选择工单类型',
                        trigger: 'blur',
                    },
                ],
                description: [{required: true, message: '请输入工单内容', trigger: 'blur'}],
                fixer: [{type:'number',required: true, message: '请选择工单处理人', trigger: 'blur'}],
                time: [{required: true, message: '请选择处理时长', trigger: 'blur'}],
                customTime: [{type:'number',required: true, message: '请输入自定义时长', trigger: 'blur',transform:(value)=> {return Number(value)}}],
                priority: [{required: true, message: '请选择优先级别', trigger: 'blur'}],
            };
            const submitting = ref(false);
            const onSubmit = () => {
                formRef.value
                    .validate()
                    .then(() => {
                        submitting.value = true;
                        submit(formState).then((response) => {
                            console.log(response);
                            submitting.value = false;
                            if (response.status == 'done') {
                                internalInstance?.proxy.$socket.sendObj({
                                    type: 'text',
                                    msg: "你有新的工单！",
                                    fixer: formState.fixer,
                                    sn: formState.sn,
                                    uname:window.localStorage.getItem('uname'),
                                    mode:1,
                                    creator:formState.creator,
                                });
                                message.success(`${response.msg} `);
                                resetForm();
                                fileList.value = [];
                                formState.customTime = 2;
                                //console.log(internalInstance?.proxy.$socket);
                            } else {
                                message.error(`${response.msg} `);
                            }

                        }).catch(error => {
                            //发生错误时执行的代码
                            console.log(error)
                        })
                    })
            };
            const resetForm = () => {
                formRef.value.resetFields();
            };
            const handleImgChange = (info: FileInfo) => {
                //console.log('1111' + info);
                const status = info.file.status;
                //console.log('22222' + status);
                if (status !== 'uploading') {
                    //console.log(toRaw(info.file.response));
                }

                if (status === 'done') {
                    //console.log(222);
                    //formRef.value.clearValidate('file');
                    message.success(`${info.file.name} 图片上传成功.`);
                    var response = toRaw(info.file.response);
                    formState.file.unshift(response.uploadImg);
                    //console.log(formState.file);
                } else if (status === 'error') {
                    //console.log(3333);
                    message.error(`${info.file.name} 图片上传失败.`);
                }
            };
            const handleFileRemove = (file) => {
                //console.log(file.response.uploadImg)
                //formState.file.value = file
                //message.warn('您点击了删除')
                console.log(file);
                //formState.file.shift();
                var index = formState.file.indexOf(file.response.uploadImg);
                //console.log(index);
                formState.file.splice(index, 1);

            }
            const isSameSn = () => {
                isSame({'sn': formState.sn}).then((response) => {
                    if (response.status == 'done') {
                        message.warning(response.msg);
                    }
                });
            }
            const users = (r) => {
                getUser({'role':r}).then((response) => {
                    if (response.status == 'done') {
                        options.value = response.users;
                    } else {
                        console.log(response.msg);
                    }
                });
            }
            const handleChange = (value: Value) => {
                formState.fixer = value; // { key: "lucy", label: "Lucy (101)" }
            };
            //const type = ref<number>(0);
            const handleChange1 = (v) => {
                console.log(v);
                formState.fixer = undefined;
                if(v.target.value == 4){
                    users(3);
                }else{
                    users(2);
                }
            };
            onMounted(() => {
            })
            return {
                submitting,
                handleImgChange,
                handleFileRemove,
                formRef,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                other: '',
                formState,
                rules,
                onSubmit,
                resetForm,
                baseUrl: baseUrl.apiUrl,
                header: {
                    authorization: store.state.token,
                },
                isSameSn,
                customTime: ref<number>(2),
                fileList,
                users,
                options,
                handleChange,
                handleChange1
            };
        },
        components: {
            InboxOutlined,
        }
    });
